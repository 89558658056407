/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-1',
  aws_cognito_identity_pool_id:
    'ap-northeast-1:adc7cb49-6fa4-4583-99e8-c7422c5b6d8a',
  aws_cognito_region: 'ap-northeast-1',
  aws_user_pools_id: 'ap-northeast-1_lgcHBvu7r',
  aws_user_pools_web_client_id: '5rt0sarfk8flfgd8e0pl40u0tg',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cloud_logic_custom: [
    {
      name: 'get-educational-material-link',
      endpoint:
        'https://zq86o7caw9.execute-api.ap-northeast-1.amazonaws.com/dev',
      region: 'ap-northeast-1',
    },
    {
      name: 'get-signed-cookie',
      endpoint:
        'https://zfc2a10k0m.execute-api.ap-northeast-1.amazonaws.com/dev',
      region: 'ap-northeast-1',
    },
  ],
};

export default awsmobile;
