/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import // /** AmplifyAuthenticator */ AmplifySignOut,
'@aws-amplify/ui-react';
import { Amplify, Auth, API } from 'aws-amplify';
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';

import pic from './logo_mark.png';

// import MainContent from './components/organisms/MainContent';
// import LearningPage from './components/pages/LearningPage';

Amplify.configure(awsconfig);

/**
 * APIのレスポンス用
 */
interface cookiePolicy {
  Policy: string;
  Signature: string;
  KeyPairId: string;
}

const App: React.FunctionComponent = () => {
  /**
   * 環境変数のロード
   * 現状、環境変数は1つしかないため、package.jsonにてscript(yarn)コマンドの引数として設定している
   */
  const envName = process.env.REACT_APP_ENV_NAME;

  // ログイン画面の値管理
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [educationalMaterialLink, setEducationalMaterialLink] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [isLogin, setIsLogin] = useState(false);

  // Cookie管理用
  const [cookies, setCookie] = useCookies([
    'CloudFront-Policy',
    'CloudFront-Signature',
    'CloudFront-Key-Pair-Id',
  ]);

  /**
   * Cookieセット用function
   * ローカルとAWS(CloudFront)で環境差異が発生するため、環境変数の設定値に応じてCookieのセット処理を切り替える
   * @param policy
   * @param signature
   * @param keyPairId
   */
  const setCookies = function setCookies(
    policy: string,
    signature: string,
    keyPairId: string,
  ) {
    console.log(`envName is ${envName}`);
    // ローカル環境の場合、domain属性のみ設定、sameSite,secure属性は設定しない
    if (envName === 'local') {
      setCookie('CloudFront-Policy', policy, {
        domain: 'school.techfun.education',
      });
      setCookie('CloudFront-Signature', signature, {
        domain: 'school.techfun.education',
      });
      setCookie('CloudFront-Key-Pair-Id', keyPairId, {
        domain: 'school.techfun.education',
      });
      // ローカル環境ではない場合、domain,sameSite,secure属性を設定
    } else {
      setCookie('CloudFront-Policy', policy, {
        domain: 'school.techfun.education',
        sameSite: 'none',
        secure: true,
      });
      setCookie('CloudFront-Signature', signature, {
        domain: 'school.techfun.education',
        sameSite: 'none',
        secure: true,
      });
      setCookie('CloudFront-Key-Pair-Id', keyPairId, {
        domain: 'school.techfun.education',
        sameSite: 'none',
        secure: true,
      });
    }
    console.log('set cookies for Signed Cookie');
  };

  /**
   * API Gateway（Cookie作成用）へのアクセス用function
   * policy, signature, keypairidを取得
   */
  const getSignedCookieFromAPI = async function getSignedCookieFromAPI() {
    const apiName = 'get-signed-cookie';
    const path = '/get-signed-cookie';
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    console.log(`myInit is ${myInit.headers.Authorization}`);

    const currentUserPool = Auth.currentUserPoolUser();
    console.log(`currentUserPool is + ${currentUserPool}`);

    API.get(apiName, path, myInit)
      .then((response) => {
        console.log(`response body is ${String(response.body)}`);
        const resJson = JSON.parse(String(response.body)) as cookiePolicy;

        // Cookieのセット処理
        setCookies(resJson.Policy, resJson.Signature, resJson.KeyPairId);
        console.log(`Policy is ${resJson.Policy}`);
        console.log(`Signature is ${resJson.Signature}`);
        console.log(`KeyPairId is ${resJson.KeyPairId}`);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  /**
   * Cognitoユーザープールのグループ名を取得するfunction
   * @returns
   */
  const getUserPoolGroupName = async function getUserPoolGroupName() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const userPoolGroupName = (await Auth.currentUserPoolUser())
      .signInUserSession.accessToken.payload['cognito:groups'];

    console.log(`userPoolGroupName is ${userPoolGroupName}`);
    if (!userPoolGroupName) {
      return null;
    }

    return String(userPoolGroupName);
  };

  /**
   * API Gateway（DynamoDB接続用）へのアクセス用function
   * @param courseName
   * @returns
   */
  const getEducationalMaterialLink = async function getEducationalMaterialLink(
    courseName: string,
  ): Promise<string> {
    const apiName = 'get-educational-material-link';
    const path = `/get-educational-material-link?name=${courseName}`;
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    const response = API.get(apiName, path, myInit)
      .then((res) => {
        console.log(res.response[0]);
        console.log(`Course Name is ${String(res.response[0].course_name.S)}`);
        console.log(
          `Educational Material Link is ${String(
            res.response[0].educational_material_link.S,
          )}`,
        );
        setMaterialName(String(res.response[0].course_name.S));

        return String(res.response[0].educational_material_link.S);
      })
      .catch((error) => {
        console.log(error.response);

        return '';
      });

    return response;
  };

  /**
   * 画面に表示するlinkをセットしているfunction
   * @param linkName
   */
  const changeEducationalMaterialLink = function changeEducationalMaterialLink(
    linkName: string,
  ) {
    setEducationalMaterialLink(String(linkName));
  };

  /**
   * ログアウトボタン押下時の処理
   * @param e
   */
  const onSignOut = (e: React.MouseEvent) => {
    // フォーム送信を抑止
    e.preventDefault();

    Auth.signOut();
    setEmail('');
    setPassword('');
    setMessage('');
    setIsLogin(false);
  };

  /**
   * ログインボタン押下時の処理
   * @param e
   */
  const onSignIn = async (e: React.MouseEvent) => {
    // フォーム送信を抑止
    // Without below, Auth.signIn returns NetworkError.
    e.preventDefault();

    if (email === '') {
      setMessage('Eメールが入力されていません');

      return;
    }
    if (password === '') {
      setMessage('パスワードが入力されていません');

      return;
    }
    setMessage('');

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const userInfo = await Auth.signIn(email, password);
      console.log(`challengeName is ${userInfo.challengeName}`);

      // ref. https://aws-amplify.github.io/docs/js/authentication#customize-your-own-components
      if (
        userInfo.challengeName === 'SMS_MFA' ||
        userInfo.challengeName === 'SOFTWARE_TOKEN_MFA' ||
        userInfo.challengeName === 'MFA_SETUP'
      ) {
        setMessage('予期しないエラーが発生しました');

        return;
      }

      // NEW_PASSWORD_REQUIREDが返却された場合、パスワード強制変更
      if (userInfo.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log(`一時パスワードを恒久パスワードに更新します`);
        await Auth.completeNewPassword(
          userInfo, // Auth.signInの返り値
          password, // 更新後のパスワード(変更前のパスワードと同一ワードで更新)
        );
        console.log(`恒久パスワードに更新しました`);
      }

      console.log('LOGIN SUCCESS!');
      getSignedCookieFromAPI();
      const courseName = await getUserPoolGroupName();
      console.log(`courseName is  ${courseName}`);
      if (!courseName) {
        setMessage('プランが設定されていません');

        return;
      }
      const responseLink = await getEducationalMaterialLink(courseName);
      console.log(`responseLink is ${responseLink}`);
      if (!responseLink) {
        setMessage('有効なプランが設定されていません');

        return;
      }
      changeEducationalMaterialLink(responseLink);
      console.log(userInfo);
      console.log(`Cookies is ${cookies}`);

      setMessage('');
      setIsLogin(true);

      // ログイン成功時リダイレクト
      document.location.href = responseLink;
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        setMessage('ユーザー登録の途中です');
        console.error('UserNotConfirmedException');
      } else if (err.code === 'PasswordResetRequiredException') {
        setMessage('パスワードを変更する必要があります');
        console.error('PasswordResetRequiredException');
      } else if (err.code === 'NotAuthorizedException') {
        setMessage('Eメールまたはパスワードが異なります');
        console.error('NotAuthorizedException');
      } else if (err.code === 'UserNotFoundException') {
        setMessage('Eメールまたはパスワードが異なります');
        console.error('UserNotFoundException');
      } else {
        setMessage('予期しないエラーが発生しました');
        console.error(err);
        console.error('Unknowned error');
      }
    }
  };

  // レンダリング
  return (
    <div>
      <div className="m-auto pt-5 text-center">
        <img src={pic} alt="" />
      </div>
      {isLogin ? (
        <div className="m-auto pt-5">
          <Container>
            <Row className="p-3">
              <Col className="text-center">
                <a href={educationalMaterialLink}>
                  <u>
                    このリンクを押して教材({materialName})を表示してください。
                  </u>
                </a>
              </Col>
            </Row>
            <Row className="m-5 p-3">
              <Col className="text-center">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={(e) => onSignOut(e)}
                >
                  Sign Out
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="w-25 m-auto pt-5">
          <p className="text-danger text-center">{message}</p>
          <Container>
            <Row className="p-3">
              <Col>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="input your Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="p-3">
              <Col>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="input your Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="p-3">
              <Col className="text-center">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={(e) => onSignIn(e)}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default App;
